import { useCallback, useReducer } from 'react'
import { enqueueSnackbar } from 'notistack'
import type { OrderProduct, User } from '@kiosk/graphql/schema/graphql'
import { useReportPassoutIssue } from './useReportPassoutIssue'

export type ReportIssueReason = {
  type: 'MISSING_FOOD' | 'MISSING_CONDIMENT' | 'POOR_QUALITY' | 'WRONG_MEAL' | 'OTHER'
  name: string
  notesRequired?: boolean
  itemsRequired?: boolean
}

export type ReportIssueItem = {
  item: OrderProduct
  selected?: boolean
}

export type ReportIssueItems = ReportIssueItem[]

export type ReportIssueModalState = {
  open: boolean
  user?: User
  items: ReportIssueItems
  reason?: ReportIssueReason
  notes?: string
  errors?: string[]
}

export type ReportIssueModalAction =
  | { type: 'OPEN_MODAL', payload: { user: User, orderProducts: OrderProduct[] } }
  | { type: 'CLOSE_MODAL' }
  | { type: 'CLEAR_MODAL' }
  | { type: 'TOGGLE_REASON', payload: ReportIssueReason['type'] }
  | { type: 'SET_NOTES', payload: string }
  | { type: 'TOGGLE_ITEM', payload: { item: OrderProduct['id'], checked: boolean } }
  | { type: 'SELECT_ITEM', payload: OrderProduct }
  | { type: 'REMOVE_ITEM', payload: OrderProduct }
  | { type: 'SET_ERRORS', payload: string[] }
  | { type: 'CLEAR_ERRORS' }

const reportIssueReasons: ReportIssueReason[] = [
  { type: 'MISSING_FOOD', name: 'Missing food', notesRequired: false, itemsRequired: true },
  { type: 'MISSING_CONDIMENT', name: 'Missing condiment(s)', notesRequired: false, itemsRequired: false },
  { type: 'POOR_QUALITY', name: 'Poor quality', notesRequired: false, itemsRequired: true },
  { type: 'WRONG_MEAL', name: 'Wrong meal', notesRequired: false, itemsRequired: true },
  { type: 'OTHER', name: 'Something else', notesRequired: true, itemsRequired: false },
]

const initialState: ReportIssueModalState = {
  open: false,
  items: [],
}

const reportIssueModalReducer = (state: ReportIssueModalState, action: ReportIssueModalAction) => {
  switch(action.type) {
    case 'OPEN_MODAL':
      const reopening = (action.payload.user.id === state.user?.id)

      return {
        ...state,
        open: true,
        user: action.payload.user,
        items: action.payload.orderProducts.map((orderProduct, idx) => ({
          item: orderProduct,
          selected: reopening ? state.items[idx]?.selected : false,
        })),
        reason: reopening ? state.reason : undefined,
        notes: reopening ? state.notes : undefined,
      }

    case 'CLOSE_MODAL':
      return {
        ...state,
        open: false,
      }

    case 'CLEAR_MODAL':
      return {
        ...state,
        open: false,
        user: undefined,
        items: [],
        reason: undefined,
        notes: undefined,
        errors: undefined,
      }

    case 'TOGGLE_REASON':
      return {
        ...state,
        reason: (state.reason?.type === action.payload) ? undefined : reportIssueReasons.find((reason) => reason.type === action.payload),
      }

    case 'TOGGLE_ITEM':
      const toggleItems = [ ...state.items ]
      const toggleIdx = toggleItems.findIndex((item) => item.item.id === action.payload.item)

      if (toggleIdx >= 0) {
        toggleItems[toggleIdx].selected = action.payload.checked
      }

      return {
        ...state,
        items: toggleItems,
      }

    case 'SET_NOTES':
      return {
        ...state,
        notes: action.payload,
      }

    case 'SET_ERRORS':
      return {
        ...state,
        errors: action.payload,
      }

    case 'CLEAR_ERRORS':
      return {
        ...state,
        errors: undefined,
      }

    default:
      return state
  }
}

export const useReportIssueModal = ({ locationId }: { locationId: string }) => {
  const [ state, dispatch ] = useReducer(reportIssueModalReducer, initialState)
  const [ reportPassoutIssue, { loading } ] = useReportPassoutIssue({
    locationId: locationId,
    userId: state.user?.id,
    reason: state.reason?.type,
    notes: state.notes,
    items: state.items.filter((item) => item.selected).map((item) => item.item.id)
  })

  const openModal = useCallback(({ user, orderProducts }: { user: User, orderProducts: OrderProduct[] }) => dispatch({ type: 'OPEN_MODAL', payload: { user, orderProducts }}), [dispatch])
  const closeModal = useCallback(() => dispatch({ type: 'CLOSE_MODAL' }), [dispatch])
  const clearModal = useCallback(() => dispatch({ type: 'CLEAR_MODAL' }), [dispatch])
  const toggleReason = useCallback((reason: ReportIssueReason['type']) => dispatch({ type: 'TOGGLE_REASON', payload: reason}), [dispatch])
  const toggleItem = useCallback((payload: { item: OrderProduct['id'], checked: boolean }) => dispatch({ type: 'TOGGLE_ITEM', payload }), [dispatch])
  const setNotes = useCallback((notes: string) => dispatch({ type: 'SET_NOTES', payload: notes}), [dispatch])

  const reportIssue = useCallback(() => {
    dispatch({ type: 'CLEAR_ERRORS' })

    reportPassoutIssue().then((response) => {
      if (response.data.reportPassoutIssue.errors?.length > 0) {
        dispatch({ type: 'SET_ERRORS', payload: response.data.reportPassoutIssue.errors.map((error) => error.message )})
      } else if (response.errors?.length > 0) {
        dispatch({ type: 'SET_ERRORS', payload: response.errors.map((error) => error.message )})
      } else {
        enqueueSnackbar(`Pass out issue reported. Thank you.`, { autoHideDuration: 2000, preventDuplicate: true })
        clearModal()
      }
    }).catch(() =>
      dispatch({ type: 'SET_ERRORS', payload: ['An unknown error occurred. Please try again.'] })
    )
  }, [reportPassoutIssue, clearModal])

  return {
    ...state,
    loading,
    reasons: reportIssueReasons,
    openModal,
    closeModal,
    clearModal,
    toggleReason,
    toggleItem,
    setNotes,
    reportIssue,
  }
}

export type UseReportIssueModalReturn = ReturnType<typeof useReportIssueModal>

export default useReportIssueModal
