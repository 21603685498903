import React, { useCallback } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Text,
  TextField,
  Checkbox,
} from 'components'
import { Close as CloseIcon } from 'icons'
import { useViewport } from 'hooks'
import CircularProgress from '@mui/material/CircularProgress'
import { DialogProps } from '@mui/material/Dialog'
import type { UseReportIssueModalReturn, ReportIssueReason } from '@kiosk/hooks/useReportIssueModal'
import type { ButtonProps } from '@mui/material'

export type ReportIssueModalReasonProps = Omit<ReportIssueReason, 'notesRequired'> & Omit<ButtonProps, 'onSelect' | 'type'> & {
  active?: boolean
  onSelect?: (type: string) => void
}

export const ReportIssueModalReason: React.FC<ReportIssueModalReasonProps> = ({ type, name, active=false, onSelect, ...props }) => {
  const onClick: React.MouseEventHandler = useCallback(() => {
    if (onSelect) onSelect(type)
  }, [ onSelect, type ])

  return <Button {...props} onClick={onClick} variant={active ? 'contained' : 'outlined'}>
    { name }
  </Button>
}

export type ReportIssueModalProps = Omit<DialogProps, 'onChange'> & Pick<UseReportIssueModalReturn, 
  | 'user'
  | 'items'
  | 'reasons'
  | 'reason'
  | 'notes'
  | 'errors'
  | 'loading'
  | 'toggleReason'
  | 'setNotes'
  | 'toggleItem'
  | 'reportIssue'
  | 'closeModal'
  | 'clearModal'>

export const ReportIssueModal: React.FC<ReportIssueModalProps> = ({
  open,
  user,
  items,
  reasons,
  reason,
  notes,
  errors,
  loading,
  toggleReason,
  setNotes,
  toggleItem,
  reportIssue,
  closeModal,
  clearModal
}) => {
  const { isLarge } = useViewport()

  return <Dialog fullScreen={!isLarge} fullWidth={isLarge} open={open} onClose={closeModal} maxWidth="sm">
    <DialogTitle sx={{ textAlign: 'center' }}>
      Report an issue for { user?.preferredName || user?.firstName } { user?.lastName }
      <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
      </IconButton>
    </DialogTitle>

    <DialogContent>
      <Box>
        <Box display="flex" flexDirection="column" rowGap={1} width="100%">
          { reasons.map((reasonOption) =>
            <ReportIssueModalReason
              key={reasonOption.type}
              size="large"
              disabled={loading}
              type={reasonOption.type}
              name={reasonOption.name}
              active={reason?.type === reasonOption.type}
              onSelect={() => toggleReason(reasonOption.type)}
            />
          ) }
        </Box>

        <Box display="flex" flexDirection="column" flexWrap="wrap" rowGap={1} width="100%" px={2} py={4}>
          { items.map((item) =>
            <Box key={item.item.id} width="100%">
              <FormControlLabel
                label={`${item.item.product.name} x ${item.item.quantity}`}
                disabled={loading}
                control={
                  <Checkbox
                    checked={item.selected}
                    onChange={(_evt, checked) => toggleItem({ item: item.item.id, checked: checked })}
                  />
                }
                sx={{ width: '100%' }}
              />
            </Box>
          ) }
        </Box>

        <Box>
          <TextField
            fullWidth
            multiline
            disabled={loading}
            type="textarea"
            minRows={3}
            label={reason?.notesRequired ? "Notes (Required)" : "Notes (Optional)"}
            placeholder="Please provide any relevant details about your issue"
            value={notes}
            onChange={(evt: React.BaseSyntheticEvent) => setNotes(evt.target.value)}
          />
        </Box>
      </Box>
    </DialogContent>

    <DialogActions>
      <Box>
        <Text color="error" fontWeight="medium">{ errors && errors.join(', ') }</Text>
      </Box>

      <Box display="flex" flexDirection="row">
        <Box>
          <Button
            disabled={loading || (!reason && items.every((item) => !item.selected) && (!notes || notes === ''))}
            variant="text"
            onClick={clearModal}
          >Cancel</Button>
        </Box>

        <Box>
          <Button
            disabled={loading || !reason || (reason.itemsRequired && items.every((item) => !item.selected)) || (reason.notesRequired && (!notes || notes === ''))}
            onClick={reportIssue}
            sx={(theme) => ({ width: theme.spacing(20) })}
          >
            { loading ? <CircularProgress color="inherit" size={28} /> : "Report Issue" }
          </Button>
        </Box>
      </Box>
    </DialogActions>
  </Dialog>
}

export default ReportIssueModal
