import React from 'react'
import { useLocation } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import { useMe } from 'hooks'
import {
  NavDrawer as AppNavDrawer,
  NavDrawerProps,
  NavMenuItem,
} from 'compositions/NavDrawer'
import { LocationSelector } from '@kiosk/components'
import { useCurrentSchoolLocation } from '@kiosk/hooks'
import { routes } from '@kiosk/routes'
import { MenuLink } from 'components'
import { ManageAccount as ManageAccountIcon } from 'icons'

export const NavigationMenuItems: React.FC<{ me: ReturnType<typeof useMe> }> = () => {
  const { hasRole, hasLevel } = useMe()
  const { schoolLocation } = useCurrentSchoolLocation()

  return <>
    { routes.filter((route) => route.parent === undefined).map((route) => {
      if (route.internal && route.role && !hasRole(route.role)) return null
      if (route.internal && route.level && !hasLevel(route.level)) return null
      if (route.role && !hasRole(route.role, schoolLocation)) return null
      if (route.level && !hasLevel(route.level, schoolLocation)) return null
      return <NavMenuItem key={route.path} route={route} routes={routes.filter((r) => r.parent === route.name)} />
    })}
  </>
}

export const AccountMenuItems: React.FC<{ me: ReturnType<typeof useMe>, onClick?: React.MouseEventHandler }> = ({ me, onClick }) => {
  const { me: user } = me
  const location = useLocation()
  const schoolLocations = user?.schoolLocations?.filter((loc) => me.hasLocationRole('KIOSK', loc))
  const { schoolLocation, setCurrentSchoolLocation } = useCurrentSchoolLocation()

  return <>
    { schoolLocations?.length > 1 && <MenuItem disableRipple disableTouchRipple sx={{ ['&:hover, &:focus, &:active, &:target, &:focus-within, &:visited, &:focus-visible']: { backgroundColor: 'inherit' } }} onKeyDown={(e) => e.stopPropagation()}>
      <LocationSelector schoolLocations={schoolLocations} currentLocation={schoolLocation} setCurrentSchoolLocation={setCurrentSchoolLocation} />
    </MenuItem> }

    { schoolLocations?.length > 1 && <Divider /> }

    <MenuItem>
      <MenuLink component={RouterLink} to="/profile" color={location.pathname === "/profile" ? "primary" : "text.primary"} onClick={onClick} onTouchEnd={onClick}>
        <ManageAccountIcon sx={{ marginRight: 1 }} />
        My Profile
      </MenuLink>
    </MenuItem>
  </>
}

export const NavDrawer: React.FC<Omit<NavDrawerProps, "AccountMenuItems" | "NavigationMenuItems">> = (props) =>{
  const { schoolLocation } = useCurrentSchoolLocation()

  return <AppNavDrawer {...props} appName="kiosk" NavigationMenuItems={NavigationMenuItems} AccountMenuItems={AccountMenuItems} currentLocation={schoolLocation} />
}

export default NavDrawer