import { useCallback, useReducer } from 'react'
import { usePosMenus } from './usePosMenus'
import { useCheckoutPosUser } from './useCheckoutPosUser'
import { MenuProduct, User } from '@kiosk/graphql/schema/graphql'

export type PosModalCartItem = {
  item: MenuProduct
  quantity: number
}

export type PosModalCart = {
  [key: string]: PosModalCartItem
}

export type PosModalState = {
  open: boolean
  user?: User
  cart: PosModalCart
  errors?: string[]
}

export type PosModalAction =
  | { type: 'OPEN_MODAL', payload: User }
  | { type: 'CLOSE_MODAL' }
  | { type: 'CLEAR_MODAL' }
  | { type: 'ADD_ITEM', payload: MenuProduct }
  | { type: 'SUBTRACT_ITEM', payload: MenuProduct }
  | { type: 'SET_ERRORS', payload: string[] }
  | { type: 'CLEAR_ERRORS' }


const initialState: PosModalState = {
  open: false,
  cart: {},
}

const posModalReducer = (state: PosModalState, action: PosModalAction) => {
  switch(action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        open: true,
        user: action.payload,
        cart: (state.user?.id === action.payload.id) ? state.cart : {},
      }
    case 'CLOSE_MODAL':
      return {
        ...state,
        open: false,
      }
    case 'CLEAR_MODAL':
      return {
        ...state,
        open: false,
        user: undefined,
        cart: {},
        errors: undefined,
      }
    case 'ADD_ITEM':
      const addItems = { ...state.cart }

      if (!!addItems[action.payload.id]) {
        addItems[action.payload.id].quantity += 1
      } else {
        addItems[action.payload.id] = {
          item: action.payload,
          quantity: 1,
        }
      }

      return {
        ...state,
        cart: { ...addItems },
      }
    case 'SUBTRACT_ITEM':
      const subItems = { ...state.cart }

      if (!!subItems[action.payload.id]) {
        const quantity = subItems[action.payload.id].quantity
        if (quantity > 1) {
          subItems[action.payload.id].quantity -= 1
        } else {
          delete subItems[action.payload.id]
        }
      }

      return {
        ...state,
        cart: { ...subItems },
      }
    case 'SET_ERRORS':
      return {
        ...state,
        errors: action.payload,
      }
    case 'CLEAR_ERRORS':
      return {
        ...state,
        errors: undefined,
      }
    default:
      return state
  }
}

export const usePosModal = ({ locationId }: { locationId: string }) => {
  const { data, loading: menusLoading } = usePosMenus({ locationId })
  const [ state, dispatch ] = useReducer(posModalReducer, initialState)
  const [ checkoutPosUser, { loading: checkoutLoading } ] = useCheckoutPosUser({ userId: state.user?.id, items: Object.values(state.cart).map((cartItem) => ({ item: cartItem.item.id, quantity: cartItem.quantity })) })

  const openModal = useCallback((user: User) => dispatch({ type: 'OPEN_MODAL', payload: user}), [dispatch])
  const closeModal = useCallback(() => dispatch({ type: 'CLOSE_MODAL' }), [dispatch])
  const clearModal = useCallback(() => dispatch({ type: 'CLEAR_MODAL' }), [dispatch])
  const addItem = useCallback((item: MenuProduct) => dispatch({ type: 'ADD_ITEM', payload: item}), [dispatch])
  const subtractItem = useCallback((item: MenuProduct) => dispatch({ type: 'SUBTRACT_ITEM', payload: item}), [dispatch])

  const checkout = useCallback(() => {
    dispatch({ type: 'CLEAR_ERRORS' })

    checkoutPosUser().then((response) => {
      if (response.data.checkoutPosUser.errors?.length > 0) {
        dispatch({ type: 'SET_ERRORS', payload: response.data.checkoutPosUser.errors.map((error) => error.message )})
      } else if (response.errors?.length > 0) {
        dispatch({ type: 'SET_ERRORS', payload: response.errors.map((error) => error.message )})
      } else {
        clearModal()
      }
    }).catch(() =>
      dispatch({ type: 'SET_ERRORS', payload: ['An unknown error occurred during checkout. Please try again.'] })
    )
  }, [checkoutPosUser, clearModal])

  const cartQuantity = useCallback((item?: MenuProduct) => {
    if (!!item) {
      return !!state.cart[item.id] ? state.cart[item.id].quantity : 0
    } else {
      return Object.values(state.cart).reduce((qty, itm) => qty += itm.quantity, 0)
    }
  }, [state])

  const cartPrice = useCallback((item?: MenuProduct) => {
    if (!!item) {
      return !!state.cart[item.id] ? (state.cart[item.id].quantity * state.cart[item.id].item.product.price) : 0
    } else {
      return Object.values(state.cart).reduce((prc, itm) => prc += (itm.quantity * itm.item.product.price), 0)
    }
  }, [state])

  return {
    ...state,
    menus: data.posMenus,
    menusLoading,
    checkoutLoading,
    checkout,
    openModal,
    closeModal,
    clearModal,
    addItem,
    subtractItem,
    cartQuantity,
    cartPrice,
  }
}

export type UsePosModalReturn = ReturnType<typeof usePosModal>

export default usePosModal
