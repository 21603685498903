import { gql } from '@kiosk/graphql/schema/gql'

export const posMenusQuery = gql(`
  query posMenusQuery($locationId: ID!) {
    posMenus(locationId: $locationId) {
      id
      name
      displayName
      startDate
      endDate
      menuProducts {
        id
        section {
          id
          name
        }
        product {
          id
          name
          featuredImage
          price(calculate: true)
        }
      }
    }
  }
`)

export default posMenusQuery
