import { useMemo } from 'react'
import { posMenusQuery } from '@kiosk/graphql/queries'
import { useKioskQuery } from './useKioskQuery'
import type { PosMenu } from '@kiosk/graphql/schema/graphql'
import { DateTime } from 'luxon'

type PosMenusQueryArgs = {
  locationId: string
}

type PosMenusArgs = PosMenusQueryArgs & {
  pollInterval?: number
}

export const usePosMenus = ({ locationId, pollInterval }: PosMenusArgs ) => {
  const { data, ...rest } = useKioskQuery<{ posMenus: PosMenu[] }, PosMenusQueryArgs>(
    posMenusQuery,
    {
      variables: {
        locationId,
      },
      pollInterval,
      // notifyOnNetworkStatusChange: true
    }
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadedAt = useMemo(() => DateTime.now(), [rest.loading]) // we want to regenerate this on every reload

  return {
    ...rest,
    loadedAt,
    data: {
      posMenus: [],
      ...data
    }
  }
}

export default usePosMenus
