import React, { useMemo } from 'react'
import { KioskUser } from '@kiosk/graphql/schema/graphql'
import { Box } from 'components'
import { UserCard } from '@kiosk/components/UserCard'
import { useUpdatePassoutStatus } from '@kiosk/hooks'
import type { UsePosModalReturn } from '@kiosk/hooks/usePosModal'
import type { UseReportIssueModalReturn } from '@kiosk/hooks/useReportIssueModal'

export type PassoutGridProps = {
  passoutEnabled: boolean
  posEnabled: boolean
  kioskUsers: KioskUser[]
  posUser: UsePosModalReturn['user']
  posCartQuantity: UsePosModalReturn['cartQuantity']
  openPosModal: UsePosModalReturn['openModal']
  openReportIssueModal: UseReportIssueModalReturn['openModal']
}

export const PassoutGridCard: React.FC<Omit<PassoutGridProps, 'kioskUsers'> & { kioskUser: KioskUser }> = ({ kioskUser, posUser, passoutEnabled, posEnabled, posCartQuantity, openPosModal, openReportIssueModal }) => {
  const [ updateOrderStatus, { loading } ] = useUpdatePassoutStatus()

  const userPosEnabled = useMemo(() => posEnabled && kioskUser.posEnabled, [posEnabled, kioskUser.posEnabled])
  const orderIds = useMemo(() => kioskUser.orderProducts.map((orderProduct) => orderProduct.orderId).filter((v, i, a) => a.indexOf(v) === i), [kioskUser.orderProducts])
  const pendingProducts = useMemo(() => kioskUser.orderProducts.map((orderProduct) => orderProduct.status).filter((v, i, a) => a.indexOf(v) === i).includes('pending'), [kioskUser.orderProducts])
  const userPosCartQuantity = useMemo(() => {
    if (posUser?.id === kioskUser.user.id) {
      return posCartQuantity()
    } else {
      return 0
    }
  } , [posCartQuantity, posUser, kioskUser.user])

  return <UserCard
    {...kioskUser}
    loading={loading}
    passedOut={!pendingProducts}
    passoutEnabled={passoutEnabled}
    posEnabled={userPosEnabled}
    onClickPassout={() => updateOrderStatus({ variables: { orderIds, status: pendingProducts ? 'fulfilled' : 'pending' } })}
    onClickAddItems={() => openPosModal(kioskUser.user)}
    onClickReportIssue={() => openReportIssueModal({ ...kioskUser })}
    posCartQuantity={userPosCartQuantity}
  />
}

export const PassoutGrid: React.FC<PassoutGridProps> = ({ kioskUsers, posUser, passoutEnabled, posEnabled, posCartQuantity, openPosModal, openReportIssueModal }) => {
return <Box display="flex" flexWrap="wrap" justifyContent="center" gap={4} pb={6}>
    { kioskUsers.map((kioskUser) => {
      return <PassoutGridCard
        key={kioskUser.user.id}
        kioskUser={kioskUser}
        posUser={posUser}
        passoutEnabled={passoutEnabled}
        posEnabled={posEnabled}
        posCartQuantity={posCartQuantity}
        openPosModal={openPosModal}
        openReportIssueModal={openReportIssueModal}
      />
    })}
  </Box>
}

export default PassoutGrid
