import { updatePassoutStatusMutation } from '@kiosk/graphql/mutations'
import { MutationTuple, useKioskMutation } from './useKioskMutation'
import type { UpdatePassoutStatusInput, UpdatePassoutStatusPayload } from '@kiosk/graphql/schema/graphql'

export type UpdatePassoutStatusMutationData = { updatePassoutStatus: UpdatePassoutStatusPayload }

export const useUpdatePassoutStatus: (variables?: UpdatePassoutStatusInput) => MutationTuple<UpdatePassoutStatusMutationData, UpdatePassoutStatusInput> = (variables)  => {
  const [ updatePassoutStatusFn, { data, ...result } ] = useKioskMutation<UpdatePassoutStatusMutationData, UpdatePassoutStatusInput>(
    updatePassoutStatusMutation,
    {
      variables,
      // notifyOnNetworkStatusChange: true
    }
  )

  return [ updatePassoutStatusFn, { ...result, data } ]
}

export default useUpdatePassoutStatus
