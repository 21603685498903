import React from 'react'
import { Box } from 'components'
import { PosMenuProduct, PosMenuProductProps } from './PosMenuProduct'
import { MenuProduct } from '@kiosk/graphql/schema/graphql'

export type PosMenuSectionProps = Omit<PosMenuProductProps, 'menuProduct'> & { menuProducts: MenuProduct[] }

export const PosMenuSection: React.FC<PosMenuSectionProps> = ({ menuProducts, checkoutLoading, addItem, subtractItem, cartQuantity }) => {
  return <Box>
    { menuProducts.map((menuProduct) =>
      <PosMenuProduct
        key={menuProduct.id}
        menuProduct={menuProduct}
        checkoutLoading={checkoutLoading}
        addItem={addItem}
        subtractItem={subtractItem}
        cartQuantity={cartQuantity}
      />
    ) }
  </Box>
}

export default PosMenuSection
