import { checkoutPosUserMutation } from '@kiosk/graphql/mutations'
import { MutationTuple, useKioskMutation } from './useKioskMutation'
import type { CheckoutPosUserInput, CheckoutPosUserMutationMutation } from '@kiosk/graphql/schema/graphql'

export const useCheckoutPosUser: (variables?: CheckoutPosUserInput) => MutationTuple<CheckoutPosUserMutationMutation, CheckoutPosUserInput> = (variables)  => {
  const [ checkoutPosUserFn, result ] = useKioskMutation<CheckoutPosUserMutationMutation, CheckoutPosUserInput>(
    checkoutPosUserMutation,
    {
      variables,
      // notifyOnNetworkStatusChange: true
    }
  )

  return [ checkoutPosUserFn, result ]
}

export default useCheckoutPosUser
