import { useCallback, useEffect, useMemo } from 'react'
import { setTag } from '@sentry/react'
import { enqueueSnackbar } from 'notistack'
import { useMe } from 'hooks'
import { useKioskContext } from './useKioskContext'
import { SchoolLocation } from '@kiosk/graphql/schema/graphql'

export type UseCurrentSchoolLocationPayload = {
  schoolLocation?: SchoolLocation
  setCurrentSchoolLocation: (location: SchoolLocation) => void
}

export const useCurrentSchoolLocation = (): UseCurrentSchoolLocationPayload => {
  const { currentSchoolLocation, setCurrentSchoolLocation: setCurrentSchoolLocationContext } = useKioskContext()
  const { me, hasLocationRole } = useMe()
  const schoolLocations = useMemo(() => me?.schoolLocations?.filter((loc) => hasLocationRole('KIOSK', loc)) || [], [ me, hasLocationRole ])
  const schoolLocation = useMemo(() => schoolLocations?.find((loc) => loc.id === currentSchoolLocation?.id) || schoolLocations?.[0], [ currentSchoolLocation, schoolLocations ])

  const setCurrentSchoolLocation = useCallback((location: SchoolLocation) => {
    setCurrentSchoolLocationContext(location)

    if (currentSchoolLocation?.id !== location?.id) {
      enqueueSnackbar(`Switched to ${location?.name || "New Campus"}`, { autoHideDuration: 2000, preventDuplicate: true })
    }
  }, [ currentSchoolLocation, setCurrentSchoolLocationContext ])

  useEffect(() => {
    if (schoolLocation && schoolLocations.length > 0 && !schoolLocations.map(loc => loc.id).includes(schoolLocation.id))
      setCurrentSchoolLocationContext(null)

    if (schoolLocation) {
      setTag("schoolLocation", `${schoolLocation.id} (${schoolLocation.name})`)
    } else {
      setTag("schoolLocation", null)
    }
  }, [ schoolLocation, schoolLocations, setCurrentSchoolLocationContext ])

  return {
    schoolLocation,
    setCurrentSchoolLocation,
  }
}

export default useCurrentSchoolLocation
