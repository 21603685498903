import React from 'react'
import { KioskUser } from '@kiosk/graphql/schema/graphql'
import type { SummarySection } from '@kiosk/hooks/useFilteredKioskUsers'
import type { UsePosModalReturn } from '@kiosk/hooks/usePosModal'
import type { UseReportIssueModalReturn } from '@kiosk/hooks/useReportIssueModal'
import { PassoutGrid } from './PassoutGrid'
import { PassoutSummaryGrid } from './PassoutSummaryGrid'

export const Passout: React.FC<{
  kioskUsers: KioskUser[]
  productSummaries: SummarySection[]
  display?: 'table' | 'grid' | null
  showSummaries?: boolean
  passoutEnabled: boolean
  posEnabled: boolean
  posUser: UsePosModalReturn['user']
  openPosModal: UsePosModalReturn['openModal']
  posCartQuantity: UsePosModalReturn['cartQuantity']
  openReportIssueModal: UseReportIssueModalReturn['openModal']
}> = ({
  kioskUsers,
  productSummaries,
  display = 'grid',
  showSummaries,
  passoutEnabled,
  posEnabled,
  posUser,
  openPosModal,
  posCartQuantity,
  openReportIssueModal,
}) => {

  return <>
    { showSummaries && <PassoutSummaryGrid productSummaries={productSummaries} /> }
    { display === 'grid' && <PassoutGrid
      kioskUsers={kioskUsers}
      posUser={posUser}
      passoutEnabled={passoutEnabled}
      posEnabled={posEnabled}
      posCartQuantity={posCartQuantity}
      openPosModal={openPosModal}
      openReportIssueModal={openReportIssueModal}
    /> }
  </>
}

export default Passout
