import React from "react"
import { LocationSelector as BaseLocationSelector, Badge } from "components"
import { SchoolLocation } from '@kiosk/graphql/schema/graphql'

export const LocationSelector: React.FC<{ schoolLocations: SchoolLocation[], currentLocation: SchoolLocation, setCurrentSchoolLocation: (location: SchoolLocation) => void }> = ({ schoolLocations, currentLocation, setCurrentSchoolLocation }) => {
  const options = schoolLocations.map((location) => ({
    label: location.name === location.organization.name ? location.name : `${location.organization.name} ${location.name}`,
    id: location.id,
    todoCount: 0, //location.todoCount,
  })).sort((a, b) => a.label.localeCompare(b.label))
  const selected = options.find(({ id }) => id === currentLocation.id)
  const badgeCount = 0 // (options.map((opt) => opt.todoCount).reduce((sum, a) => sum + a, 0) || 0) - (selected?.todoCount || 0)

  return <Badge sx={{ width: '100%'}} badgeContent={badgeCount} color="error">
    <BaseLocationSelector label="Selected Campus" options={options} selected={selected} onChange={(value) => setCurrentSchoolLocation(schoolLocations.find((location) => value.id === location.id))} />
  </Badge>
}

export default LocationSelector
