import React from 'react'
import { Box, Card, CardContent, Text } from 'components'
import { SummarySection } from '@kiosk/hooks/useFilteredKioskUsers'

export const PassoutSummary: React.FC<SummarySection> = ({
  id,
  title,
  products,
}) => {
  return (
    <Card
      key={id}
      sx={(theme) => ({
        px: 0,
        pb: 0,
        backgroundColor: theme.palette.grey[200],
      })}
    >
      <Text variant="h6" sx={{ textWrap: 'balance', p: 2 }}>
        {title}
      </Text>

      <CardContent sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        borderTopColor: theme.palette.divider,
        textWrap: 'balance',
        p: 2,
      })}>
        {products.map((p) => (
          <Box
            key={p.id}
            display="flex"
            alignItems="flex-start"
            py={0.5}
          >
            <Box flexShrink={0} textAlign="right" sx={(theme) => ({ minWidth: theme.spacing(5) })}>
              {p.count} ×
            </Box>
            <Box ml={1} flexGrow={1} textAlign="left">
              {p.name}
            </Box>
          </Box>
        ))}
      </CardContent>
    </Card>
  )
}

export default PassoutSummary
