import { gql } from '@kiosk/graphql/schema/gql'

export const checkoutPosUserMutation = gql(`
  mutation checkoutPosUserMutation($input: CheckoutPosUserInput!) {
    checkoutPosUser(input: $input) {
      kioskUser {
        id
        posEnabled
        posPaymentMethod
        orderProducts {
          ...OrderProductFields
        }
      }
      errors {
        code
        message
      }
    }
  }
`)

export default checkoutPosUserMutation
