import { useMemo } from 'react'
import { kioskUsersQuery } from '@kiosk/graphql/queries'
import { useKioskQuery } from './useKioskQuery'
import type { Scalars, KioskUser } from '@kiosk/graphql/schema/graphql'
import { DateTime } from 'luxon'

type KioskUsersQueryArgs = {
  locationId: string
  date?: Scalars['ISO8601Date']['input']
}

type KioskUsersArgs = KioskUsersQueryArgs & {
  pollInterval?: number
}

export const useKioskUsers = ({ locationId, date, pollInterval }: KioskUsersArgs ) => {
  const { data, ...rest } = useKioskQuery<{ kioskUsers: KioskUser[] }, KioskUsersQueryArgs>(
    kioskUsersQuery,
    {
      variables: {
        locationId,
        date,
      },
      pollInterval,
      // notifyOnNetworkStatusChange: true
    }
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadedAt = useMemo(() => DateTime.now(), [rest.loading]) // we want to regenerate this on every reload

  return {
    ...rest,
    loadedAt,
    data: {
      kioskUsers: [],
      ...data
    }
  }
}

export default useKioskUsers
