import { gql } from '@kiosk/graphql/schema/gql'

export const updatePassoutStatusMutation = gql(`
  mutation updatePassoutStatusMutation($input: UpdatePassoutStatusInput!) {
    updatePassoutStatus(input: $input) {
      orders {
        id
        status
        orderProducts {
          ...OrderProductFields
        }
      }
    }
  }
`)

export default updatePassoutStatusMutation
