import React from 'react'
import { FilterProps } from '@kiosk/hooks/useFilteredKioskUsers'
import { FiltersDrawer } from './FiltersDrawer'
import { FiltersHeader } from './FiltersHeader'

export const Filters: React.FC<FilterProps & { filteredCount: number, totalCount: number }> = ({
  loading,
  loadedAt,
  availableRosters,
  availableMenus,
  appliedFilters,
  filters,
  filterDefinitions,
  applicable,
  clearable,
  filteredCount,
  totalCount,
  setFilters,
  applyFilters,
  setAndApplyFilters,
  clearFilters,
}) => {
  return <>
    <FiltersDrawer
      filters={filters}
      filterDefinitions={filterDefinitions}
      applicable={applicable}
      clearable={clearable}
      setFilters={setFilters}
      applyFilters={applyFilters}
      setAndApplyFilters={setAndApplyFilters}
      clearFilters={clearFilters}
    />

    <FiltersHeader
      loading={loading}
      loadedAt={loadedAt}
      filters={filters}
      appliedFilters={appliedFilters}
      filterDefinitions={filterDefinitions}
      availableRosters={availableRosters}
      availableMenus={availableMenus}
      filteredCount={filteredCount}
      totalCount={totalCount}
      setFilters={setFilters}
      applyFilters={applyFilters}
      setAndApplyFilters={setAndApplyFilters}
    />
  </>
}

export default Filters
