import { useContext } from 'react'
import { ApplicationContext } from '@kiosk/compositions/Application'
import type { KioskContext } from '@kiosk/compositions/Application'

export { KioskContext }

export const useKioskContext = (): KioskContext => {
  const kioskContext = useContext<KioskContext>(ApplicationContext)
  return kioskContext
}

export default useKioskContext
