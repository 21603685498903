import { gql } from '@kiosk/graphql/schema/gql'

export const kioskUsersQuery = gql(`
  query kioskUsersQuery($locationId: ID!, $date: ISO8601Date!) {
    kioskUsers(locationId: $locationId, date: $date) {
      id
      posEnabled
      posPaymentMethod
      user {
        id
        firstName
        preferredName
        middleName
        lastName
        displayName
        suffix
        roster(locationId: $locationId, date: $date) {
          id
          name
          description
          group
          groupName
          sortOrder
        }
      }
      orderProducts {
        ...OrderProductFields
      }
    }
  }
`)

export default kioskUsersQuery
