import React, { useMemo } from 'react'
import {
  Box,
  Button,
  Currency,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Text,
} from 'components'
import { Close as CloseIcon } from 'icons'
import { useViewport } from 'hooks'
import { DialogProps } from '@mui/material/Dialog'
import CircularProgress from '@mui/material/CircularProgress'
import { PosMenu } from '@kiosk/graphql/schema/graphql'
import { PosMenuSection } from './PosMenuSection'
import type { UsePosModalReturn } from '@kiosk/hooks/usePosModal'
import { groupBy } from 'lodash'

export type PosModalProps = Pick<UsePosModalReturn, 'user' | 'cart' | 'errors' | 'closeModal' | 'addItem' | 'subtractItem' | 'cartQuantity' | 'cartPrice' | 'menusLoading' | 'checkoutLoading' | 'checkout'> & {
  menus: PosMenu[]
} & Omit<DialogProps, 'onChange'>

export const PosModal: React.FC<PosModalProps> = ({ open, user, errors, menus, checkoutLoading, checkout, closeModal, addItem, subtractItem, cartQuantity, cartPrice }) => {
  const { isLarge } = useViewport()
  const totalQuantity = useMemo(() => cartQuantity(), [cartQuantity])
  const totalPrice = useMemo(() => cartPrice() / 100, [cartPrice]) // TODO: Format currency

  return <Dialog fullScreen={!isLarge} fullWidth={isLarge} open={open} onClose={closeModal} maxWidth="sm">
    <DialogTitle sx={{ textAlign: 'center' }}>
      Adding items for { user?.preferredName || user?.firstName } { user?.lastName }
      <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
      </IconButton>
    </DialogTitle>

    <DialogContent sx={{ p: 0 }}>
      <Box display="flex" flexDirection="column" width="100%">
        { menus.flatMap((menu) =>
          Object.values(groupBy(menu.menuProducts, (menuProduct) => menuProduct.section.id)).map((section) =>
            <PosMenuSection
              key={section[0].section.id}
              menuProducts={section}
              addItem={addItem}
              subtractItem={subtractItem}
              cartQuantity={cartQuantity}
              checkoutLoading={checkoutLoading}
            />
          )
        ) }
      </Box>
    </DialogContent>

    <DialogActions>
      <Box display="flex" width="100%" justifyContent="space-between" alignItems="center" p={1} gap={2}>
        <Box flexShrink={0}>
          <Text fontWeight="medium">
            { totalQuantity } items { totalQuantity > 0 && <>@ <Currency value={totalPrice} /></> }
          </Text>
        </Box>

        <Box>
          <Text color="error" fontWeight="medium">{ errors && errors.join(', ') }</Text>
        </Box>

        <Box>
          <Button
            disabled={totalQuantity <= 0 || checkoutLoading}
            onClick={checkout}
            sx={(theme) => ({ minWidth: theme.spacing(20) }) }
          >
            { checkoutLoading ? <CircularProgress color="inherit" size={28} /> : "Checkout" }
          </Button>
        </Box>
      </Box>
    </DialogActions>
  </Dialog>
}

export default PosModal
