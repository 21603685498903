import React, { PropsWithChildren, createContext, useCallback, useReducer } from 'react'
import { useLocalStorage } from 'hooks'
import { SchoolLocation } from '@kiosk/graphql/schema/graphql'

export type KioskContext = {
  currentSchoolLocation?: SchoolLocation
  setCurrentSchoolLocation?: (location: SchoolLocation) => void
}

const initialState: KioskContext = {}

export const ApplicationContext = createContext<KioskContext>(initialState)

export type ContextAction =
  | { type: 'SET_CURRENT_SCHOOL', payload: SchoolLocation }

const contextReducer = (state: KioskContext, action: ContextAction) => {
  switch(action.type) {
    case 'SET_CURRENT_SCHOOL':
      return {
        ...state,
        currentSchoolLocation: action.payload,
      }
    default:
      return state
  }
}

export const ContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [stored, setStored] = useLocalStorage<KioskContext>('kioskContext', initialState)

  const localStorageReducer = useCallback(
    (state: KioskContext, action: ContextAction) => {
      const newState = contextReducer(state, action)
      setStored(newState)
      return newState
    },
    [setStored]
  )
  const [state, dispatch] = useReducer(localStorageReducer, stored)

  const setCurrentSchoolLocation = useCallback(
    (location: SchoolLocation) => dispatch({ type: 'SET_CURRENT_SCHOOL', payload: location}),
    [dispatch]
  )

  const contextValue: KioskContext = {
    ...state,
    setCurrentSchoolLocation,
  }

  return <ApplicationContext.Provider value={contextValue}>
    {children}
  </ApplicationContext.Provider>
}

export default ContextProvider