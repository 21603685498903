import React from 'react'
import { EmptyState, Page } from 'components'
import { Spinner } from 'components/Loading'
import { useCurrentSchoolLocation, useFilteredKioskUsers, usePosModal, useReportIssueModal } from '@kiosk/hooks'
import { PosModal, ReportIssueModal } from '@kiosk/components'
import { Passout as PassoutReport, Filters as PassoutFilters } from '@kiosk/compositions/Passout'
import { useQueryParams } from 'hooks/useQueryParams'
import ReceiptIcon from '@mui/icons-material/Receipt'

export const Passout: React.FC = () => {
  const params = useQueryParams<{
    date: string,
  }>({
    date: undefined,
  })

  const { schoolLocation } = useCurrentSchoolLocation()

  const {
    loading,
    loadedAt,
    error,
    filters,
    passoutEnabled,
    posEnabled,
    data: {
      kioskUsers,
      filteredKioskUsers,
      productSummaries,
      filteredCount,
      totalCount,
    },
  } = useFilteredKioskUsers(
    schoolLocation,
    {
      date: params.date,
    }
  )

  const posModal = usePosModal({ locationId: schoolLocation?.id })
  const reportIssueModal = useReportIssueModal({ locationId: schoolLocation?.id })

  return <Page fullWidth>
    <PassoutFilters
      loading={loading}
      loadedAt={loadedAt}
      filteredCount={filteredCount}
      totalCount={totalCount}
      {...filters}
    />

    <PosModal {...posModal} />
    <ReportIssueModal {...reportIssueModal} />

    { ((!kioskUsers || kioskUsers.length === 0) && (loading)) && <Spinner message="Loading Orders..." /> }
    { ((filteredKioskUsers && filteredKioskUsers.length > 0) && (!error)) && <PassoutReport
      kioskUsers={filteredKioskUsers}
      productSummaries={productSummaries}
      showSummaries={filters.appliedFilters.showSummaries}
      passoutEnabled={passoutEnabled}
      posEnabled={posEnabled && posModal.menus.length > 0}
      posUser={posModal.user}
      openPosModal={posModal.openModal}
      posCartQuantity={posModal.cartQuantity}
      openReportIssueModal={reportIssueModal.openModal}
    /> }
    { ((!filteredKioskUsers || filteredKioskUsers.length === 0) && (kioskUsers && kioskUsers.length > 0) && (!error)) && <EmptyState icon={ReceiptIcon} message="No data to display." /> }
  </Page>
}

export default Passout
