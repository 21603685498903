import React, { useMemo } from 'react'
import { Image } from 'mui-image'
import {
  Box,
  Button,
  ButtonGroup,
  Text,
} from 'components'
import { MenuProduct } from '@kiosk/graphql/schema/graphql'
import type { UsePosModalReturn } from '@kiosk/hooks/usePosModal'
import { useViewport } from 'hooks'
import { truncate } from 'lodash'

type MenuProductButtonGroupProps = {
  disabled: boolean
  quantity: number
  addItem: () => void
  subtractItem: () => void
}

const MenuProductButtonGroup: React.FC<MenuProductButtonGroupProps> = ({ disabled, quantity, addItem, subtractItem}) => {
  return <Box>
    <ButtonGroup size="small">
      { quantity > 0 && <Button
        color="primary"
        disabled={disabled || quantity <= 0}
        fullWidth={false}
        onClick={() => subtractItem()}
        sx={{ opacity: (quantity > 0) ? 1 : 0.5, borderRadius: '50%' }}
      >-</Button> }

      { quantity > 0 && <Box
        px={2}
        width={(theme) => theme.spacing(8)}
        border={(theme) => `solid 1px ${theme.palette.divider}`}
      >
        <Text
          fontWeight={(quantity > 0) ? 'normal' : 'light'}
          variant="h5"
          lineHeight="inherit"
          textAlign="center"
          color={(theme) => (quantity > 0) ? theme.palette.text.primary : theme.palette.text.disabled}
        >
          { quantity }
        </Text>
      </Box> }

      <Button
        color="primary"
        disabled={disabled}
        fullWidth={false}
        onClick={() => addItem()}
        sx={{ borderRadius: '50%' }}
      >+</Button>
    </ButtonGroup>
  </Box>
}

const SectionName: React.FC<{ name: string }> = ({ name }) => {
  return <Text
    variant="h6"
    fontWeight="normal"
    fontSize="1rem"
    color={(theme) => theme.palette.text.disabled}
  >
    { name }
  </Text>
}

const ProductName: React.FC<{ name: string, truncateLength?: number }> = ({ name, truncateLength }) => {
  return <Text
    variant="h6"
    fontWeight="normal"
    title={name}
  >
    { !!truncateLength ? truncate(name, { length: truncateLength }) : name }
  </Text>
}

export type PosMenuProductProps = Pick<UsePosModalReturn, 'addItem' | 'subtractItem' | 'cartQuantity' | 'checkoutLoading'> & { menuProduct: MenuProduct }

export const PosMenuProduct: React.FC<PosMenuProductProps> = ({ menuProduct, checkoutLoading, addItem, subtractItem, cartQuantity }) => {
  const { isSmall } = useViewport()
  const quantity = useMemo(() => cartQuantity(menuProduct), [menuProduct, cartQuantity])

  if (isSmall) {
    return <Box display="flex" alignItems="stretch" pr={2} borderBottom={(theme) => `1px solid ${theme.palette.divider}`}>
      <Box mr={2} width="96px" height="96px">
        <Image width="96px" height="96px" src={menuProduct.product.featuredImage} duration={1000} />
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1}>
        <Box display="flex" flexDirection="column" justifyContent="space-evenly" alignSelf="stretch">
          <ProductName name={menuProduct.product.name} />
          <SectionName name={menuProduct.section.name} />
        </Box>

        <Box justifySelf="center">
          <MenuProductButtonGroup
            disabled={checkoutLoading}
            quantity={quantity}
            addItem={() => addItem(menuProduct)}
            subtractItem={() => subtractItem(menuProduct)}
          />
        </Box>
      </Box>
    </Box>
  } else {
    return <Box display="flex" borderBottom={(theme) => `1px solid ${theme.palette.divider}`}>
      <Box mr={2} width="96px" height="96px">
        <Image width="96px" height="96px" src={menuProduct.product.featuredImage} duration={1000} />
      </Box>

      <Box display="flex" flexDirection="column" flexGrow={1} justifyContent="space-around" pb={2}>
        <Box>
          <ProductName name={menuProduct.product.name} truncateLength={30} />
        </Box>

        <Box display="flex" justifyContent="space-between" pr={2}>
          <Box>
            <SectionName name={menuProduct.section.name} />
          </Box>

          <MenuProductButtonGroup
            disabled={checkoutLoading}
            quantity={quantity}
            addItem={() => addItem(menuProduct)}
            subtractItem={() => subtractItem(menuProduct)}
          />
        </Box>
      </Box>
    </Box>
  }
}

export default PosMenuProduct
