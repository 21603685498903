import React, { useCallback } from 'react'
import {
  Box,
  Button,
  Text,
  DatePicker,
  DateButtonFilter,
  CheckboxFilter,
  TextFieldFilter,
} from 'components'
import { ContentDrawerPortal } from 'compositions/ContentDrawer'
import { DateTime } from 'luxon'
import { FilterProps } from '@kiosk/hooks/useFilteredKioskUsers'

export type FiltersDrawerProps = Pick<FilterProps, "filters" | "filterDefinitions" | "setFilters" | "setAndApplyFilters" | "applyFilters" | "clearFilters" | "applicable" | "clearable">

export const FiltersDrawer: React.FC<FiltersDrawerProps> = ({ filters, filterDefinitions, /*applicable,*/ clearable, setFilters, applyFilters, setAndApplyFilters, clearFilters }) => {
  const onDateChange = useCallback((newDate: DateTime) => setAndApplyFilters({ date: (newDate || filters.date)}), [filters.date, setAndApplyFilters])

  return <ContentDrawerPortal overflow="hidden" drawerType="filters">
    <Box height="100%" display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column" overflow="auto" p={2}>
        <Box pb={4}>
          <Text variant="h5" fontWeight="medium">Filters</Text>
        </Box>

        <Box width="100%" pb={4}>
          <Text variant="subtitle1" fontWeight="medium" pb={2}>Date</Text>

          <DatePicker
            closeOnSelect={true}
            value={filters.date}
            onAccept={onDateChange}
            sx={{ width: '100%' }}
          />

          <Box pt={2} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignContent: 'space-around' }}>
            <Box>
              <DateButtonFilter
                date={filters.date}
                newDate={DateTime.now().setZone(filters.timeZone).startOf('day')}
                setFilters={setAndApplyFilters}
                sx={(theme) => {
                  const isToday = DateTime.now().setZone(filters.timeZone).toFormat("EEE, LLL d") === filters.date.toFormat("EEE, LLL d")
                  return {
                    mr: 1,
                    backgroundColor: isToday ? theme.palette.success.light : undefined,
                    color: isToday ? theme.palette.success.contrastText : undefined,
                  }
                }}
              >Today</DateButtonFilter>
             </Box>


             <Box>
              <DateButtonFilter
                date={filters.date}
                newDate={DateTime.now().setZone(filters.timeZone).startOf('day').plus({ days: 1 })}
                setFilters={setAndApplyFilters}
              >Tomorrow</DateButtonFilter>
            </Box>
          </Box>

          <Box pt={2} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignContent: 'space-around' }}>
            <Box>
              <DateButtonFilter
                date={filters.date}
                newDate={filters.date.setZone(filters.timeZone).minus({ days: 1 }).startOf('day', { useLocaleWeeks: true })}
                setFilters={setAndApplyFilters}
              >&lt; { filters.date.setZone(filters.timeZone).minus({ days: 1 }).startOf('day', { useLocaleWeeks: true }).toFormat('LLL d') }</DateButtonFilter>
            </Box>

             <Box>
              <DateButtonFilter
                date={filters.date}
                newDate={filters.date.setZone(filters.timeZone).plus({ days: 1 }).startOf('day', { useLocaleWeeks: true })}
                setFilters={setAndApplyFilters}
              >{ filters.date.setZone(filters.timeZone).plus({ days: 1 }).startOf('day', { useLocaleWeeks: true }).toFormat('LLL d') } &gt;</DateButtonFilter>
            </Box>
          </Box>
        </Box>

        { filterDefinitions.find((definition) => definition.name === 'menuIds')?.options?.length > 1 &&
          <CheckboxFilter filterName="menuIds" filterDefinitions={filterDefinitions} filters={filters} setFilters={setAndApplyFilters} /> }

        <TextFieldFilter filterName="studentName" filterDefinitions={filterDefinitions} filters={filters} setFilters={setFilters} applyFilters={applyFilters} setAndApplyFilters={setAndApplyFilters} liveFilter />

        <CheckboxFilter filterName="rosterIds" filterDefinitions={filterDefinitions} filters={filters} setFilters={setAndApplyFilters} />
      </Box>

      <Box p={2}>
        <Button onClick={() => clearFilters()} color="inherit" variant="text" disabled={!clearable}>Clear</Button>
        <Button variant="contained" onClick={applyFilters}>Apply</Button>
      </Box>
    </Box>
  </ContentDrawerPortal>
}

export default FiltersDrawer
