import React from 'react'
import { Wrapper, Layout } from 'compositions/Application'
import Routes from '@kiosk/routes'
import { theme } from '@kiosk/theme'
import NavBar from '@kiosk/compositions/NavBar'
import NavDrawer from '@kiosk/compositions/NavDrawer'
import { ContextProvider } from './Context'

export const Application: React.FC = () =>
  <Wrapper theme={theme} apolloClient="kiosk">
    <ContextProvider>
      <Layout navBar={NavBar} navDrawer={NavDrawer}>
        <Routes />
      </Layout>
    </ContextProvider>
  </Wrapper>

export default Application
