import React from 'react'
import { Box } from 'components'
import { SummarySection } from '@kiosk/hooks/useFilteredKioskUsers'
import { PassoutSummary } from './PassoutSummary'

export interface PassoutSummaryGridProps {
    productSummaries: SummarySection[]
}

export const PassoutSummaryGrid: React.FC<PassoutSummaryGridProps> = ({ productSummaries }) => {
    return (
        <Box display="flex" flexWrap="wrap" justifyContent="center" gap={4} pb={6}>
            { productSummaries.map((section) => (
                <PassoutSummary key={section.id} {...section} />
            )) }
        </Box>
    )
}

export default PassoutSummaryGrid
