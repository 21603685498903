import { reportPassoutIssueMutation } from '@kiosk/graphql/mutations'
import { MutationTuple, useKioskMutation } from './useKioskMutation'
import type { ReportPassoutIssueInput, ReportPassoutIssueMutationMutation } from '@kiosk/graphql/schema/graphql'

export const useReportPassoutIssue: (variables?: ReportPassoutIssueInput) => MutationTuple<ReportPassoutIssueMutationMutation, ReportPassoutIssueInput> = (variables)  => {
  const [ reportPassoutIssueFn, result ] = useKioskMutation<ReportPassoutIssueMutationMutation, ReportPassoutIssueInput>(
    reportPassoutIssueMutation,
    {
      variables,
      // notifyOnNetworkStatusChange: true
    }
  )

  return [ reportPassoutIssueFn, result ]
}

export default useReportPassoutIssue
