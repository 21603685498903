import { gql } from '@kiosk/graphql/schema/gql'

export const reportPassoutIssueMutation = gql(`
  mutation reportPassoutIssueMutation($input: ReportPassoutIssueInput!) {
    reportPassoutIssue(input: $input) {
      requestId
      kioskUser {
        id
        orderProducts {
          ...OrderProductFields
        }
      }
      errors {
        code
        message
      }
    }
  }
`)

export default reportPassoutIssueMutation
