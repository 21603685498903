import { gql } from '@kiosk/graphql/schema/gql'

export const updateOrderStatusMutation = gql(`
  mutation updateOrderStatusMutation($input: UpdateOrderStatusInput!) {
    updateOrderStatus(input: $input) {
      orders {
        id
        status
        orderProducts {
          ...OrderProductFields
        }
      }
    }
  }
`)

export default updateOrderStatusMutation
